import { Australia, Sweden, Switzerland, Usa } from '../images/Reviews/flags';
import { AustraliaUser, SwedenUser, SwitzerlandUser, UsaUser } from '../images/Reviews/userFlags';
import FullStars from '../images/Reviews/FullStars.png';
import HalfStars from '../images/Reviews/HalfStars.png';

const dataForReviews = [
  {
    key: 1,
    img: Usa,
    userImg: UsaUser,
    starImg: HalfStars,
    title1: 'iot software firm,',
    title2: 'co-founder',
    totalScore: '4.7',
    marks: [
      {
        key: 1,
        param: 'reviews.quality',
        value: '5.0'
      },
      {
        key: 2,
        param: 'reviews.shedule',
        value: '4.5'
      },
      {
        key: 3,
        param: 'reviews.cost',
        value: '4.5'
      },
      {
        key: 4,
        param: 'reviews.refer',
        value: '5.0'
      },
    ],
    header: "«the ossystem's team works proactively to solve problems quickly and thoroughly»",
    description: 'The OSSystems team employs a broad variety of skill sets to create high-quality, cohesive deliverables. The team integration process has been fluid and effective. Emphases on collaboration and communication have been key in developing a long-term partnership.'
  },
  {
    key: 2,
    img: Switzerland,
    userImg: SwitzerlandUser,
    starImg: FullStars,
    title1: 'dating app,',
    title2: 'ceo and co-founder',
    totalScore: '5.0',
    marks: [
      {
        key: 1,
        param: 'reviews.quality',
        value: '5.0'
      },
      {
        key: 2,
        param: 'reviews.shedule',
        value: '5.0'
      },
      {
        key: 3,
        param: 'reviews.cost',
        value: '5.0'
      },
      {
        key: 4,
        param: 'reviews.refer',
        value: '5.0'
      },
    ],
    header: "«ossystem's background in our core technologies is what we were looking for in a partnership»",
    description: 'OSSystem effectively completed all the tasks requested. The app is updated and currently bug-free. Their team’s commitment and work quality were outstanding. They streamlined the project, striving to fix any inconsistencies in their work. Expect a true partnership when working with OSSystem.'
  },
  {
    key: 3,
    img: Australia,
    userImg: AustraliaUser,
    starImg: HalfStars,
    title1: 'data analytics company,',
    title2: 'co-founder',
    totalScore: '4.7',
    marks: [
      {
        key: 1,
        param: 'reviews.quality',
        value: '4.5'
      },
      {
        key: 2,
        param: 'reviews.shedule',
        value: '4.5'
      },
      {
        key: 3,
        param: 'reviews.cost',
        value: '5.0'
      },
      {
        key: 4,
        param: 'reviews.refer',
        value: '5.0'
      },
    ],
    header: '«they had a really good understanding of what we wanted to achieve from a user experience point of view»',
    description: 'Although the platform is still in the pre-launch stages, OSSystem earned positive feedback on the professionalism and attractiveness of the UI design. Their quality of work, daily communication, and proactiveness has provided depth and range in their professional relationship.'
  },
  {
    key: 4,
    img: Sweden,
    userImg: SwedenUser,
    starImg: HalfStars,
    title1: 'Martin Karlsson,',
    title2: 'co-founder',
    totalScore: '4.7',
    marks: [
      {
        key: 1,
        param: 'reviews.quality',
        value: '4.6'
      },
      {
        key: 2,
        param: 'reviews.shedule',
        value: '4.6'
      },
      {
        key: 3,
        param: 'reviews.cost',
        value: '4.8'
      },
      {
        key: 4,
        param: 'reviews.refer',
        value: '4.7'
      },
    ],
    header: '«os-system team delivered more than can be expected to meet the tough real-time requirements of this project»',
    description: 'The code delivered was of excellent quality and the issues that came up were fixed and turned around really quickly. Developers, who work for OS-system, get the job done regardless of it`s late at night or weekend.'
  },
  {
    key: 5,
    img: Australia,
    userImg: AustraliaUser,
    starImg: FullStars,
    title1: 'Mark W,',
    title2: 'co-founder',
    totalScore: '5.0',
    marks: [
      {
        key: 1,
        param: 'reviews.quality',
        value: '5.0'
      },
      {
        key: 2,
        param: 'reviews.shedule',
        value: '5.0'
      },
      {
        key: 3,
        param: 'reviews.cost',
        value: '5.0'
      },
      {
        key: 4,
        param: 'reviews.refer',
        value: '5.0'
      },
    ],
    header: '«I highly recommend, and will not hesitate to use again for future jobs»',
    description: 'The project was completed very successfully - starting with Proof of Concept, through to final Enterprise Platform development. Highly professional, diligent and customer- focused. Great depth and breadth of skill sets, which helped de-risk the project, while also giving us resource flexibility.'
  },

];

export default dataForReviews;
