import React from 'react';
import { useTranslation } from 'react-i18next';

import Logo from '../../images/Logo';
import { Phone, Email, Site, Social } from '../../images/Footer';

import './Footer.scss';

export default function Footer() {

  const { t } = useTranslation();

  const currentYear = new Date().getFullYear();

  return (
    <>
      <div className='footer-container container-fluid'>
        <div className='footer-wrapper container'>

          <div className='footer-contacts row justify-content-between'>

            <div className='footer-contact col-12 col-sm-6 col-lg-3'>
              <Phone />
              <span className='footer-contact-label'>{t('footer.phone')}</span>
              <a href='tel:+38 095 279 42 12' className='footer-contact-link phone-link'>+38 095 279 42 12</a>
            </div>

            <div className='footer-contact col-12 col-sm-6 col-lg-3'>
              <Email />
              <span className='footer-contact-label'>{t('footer.email')}</span>
              <a href='mailto:office@os-system.com' className='footer-contact-link email-link'>office@os-system.com</a>
            </div>

            <div className='footer-contact col-12 col-sm-6 col-lg-3'>
              <Site />
              <span className='footer-contact-label'>{t('footer.site')}</span>
              <a href='https://os-system.com/' target='_blank' rel='noreferrer' className='footer-contact-link site-link'>os-system.com</a>
            </div>

            <div className='footer-contact col-12 col-sm-6 col-lg-3'>
              <Social />
              <span className='footer-contact-label'>{t('footer.socials')}</span>
              <div className='social-network-container'>
                <a href='https://www.linkedin.com/company/ossystem/' target='_blank' rel='noreferrer noopener' className='social-network-link linkedin'>
                  <span className='social-network-icon linkedin' />
                </a>
                <a href='https://www.facebook.com/ossystem.ltd/' target='_blank' rel='noreferrer noopener' className='social-network-link facebook'>
                  <span className='social-network-icon facebook' />
                </a>
              </div>
            </div>

          </div>

        </div>
      </div>
      <div className='footer-stripe container-fluid'>
        <div className='footer-stripe-wrapper container'>
          <div className='footer-logo-container row justify-content-center'>
            <div className='footer-logo'>
              <a href='https://os-system.com' target='_blank' rel='noreferrer'>
                <Logo svgClass='logo-img' />
              </a>
              <span className='footer-copyright'>{currentYear}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
