import { FootballLogo, MegazoneLogo, RealtyLogo, RemoteLogo } from '../images/Portfolio/logos';
import { FootballVideo, MegazoneVideo, RealtyVideo, RemoteVideo } from '../images/Portfolio/video';
import { FootballIcon, MegazoneIcon, RealtyIcon, RemoteIcon } from '../images/Portfolio/backgrounds';

const dataForPortfolio = [
  {
    key: 1,
    logo: MegazoneLogo,
    video: MegazoneVideo,
    background: MegazoneIcon,
    projectClass: 'megazone',
    animationDirection: 'slide-right',
    text: [
      {
        key: 1,
        title: 'projects.megazone.name.title',
        description: 'projects.megazone.name.description'
      },
      {
        key: 2,
        title: 'projects.megazone.platform.title',
        description: 'projects.megazone.platform.description'
      },
      {
        key: 3,
        title: 'projects.megazone.technology.title',
        description: 'projects.megazone.technology.description'
      },
      {
        key: 4,
        title: 'projects.megazone.team.title',
        description: [
          {
            key: 1,
            text: 'projects.megazone.team.description1',
          },
          {
            key: 2,
            text: 'projects.megazone.team.description2',
          },
          {
            key: 3,
            text: 'projects.megazone.team.description3',
          },
          {
            key: 4,
            text: 'projects.megazone.team.description4',
          },
          {
            key: 5,
            text: 'projects.megazone.team.description5',
          },
        ]
      },
      {
        key: 5,
        title: 'projects.megazone.duration.title',
        description: 'projects.megazone.duration.description'
      },
      {
        key: 6,
        title: 'projects.megazone.client.title',
        description: 'projects.megazone.client.description'
      },
    ]
  },
  {
    key: 2,
    logo: FootballLogo,
    video: FootballVideo,
    background: FootballIcon,
    projectClass: 'football',
    animationDirection: 'slide-left',    
    text: [
      {
        key: 1,
        title: 'projects.football.name.title',
        description: 'projects.football.name.description'
      },
      {
        key: 2,
        title: 'projects.football.platform.title',
        description: 'projects.football.platform.description'
      },
      {
        key: 3,
        title: 'projects.football.technology.title',
        description: 'projects.football.technology.description'
      },
      {
        key: 4,
        title: 'projects.football.team.title',
        description: [
          {
            key: 1,
            text: 'projects.football.team.description1',
          },
          {
            key: 2,
            text: 'projects.football.team.description2',
          },
          {
            key: 3,
            text: 'projects.football.team.description3',
          },
          {
            key: 4,
            text: 'projects.football.team.description4',
          },
          {
            key: 5,
            text: 'projects.football.team.description5',
          },
        ]
      },
      {
        key: 5,
        title: 'projects.football.duration.title',
        description: 'projects.football.duration.description'
      },
      {
        key: 6,
        title: 'projects.football.client.title',
        description: 'projects.football.client.description'
      },
    ]
  },
  {
    key: 3,
    logo: RealtyLogo,
    video: RealtyVideo,
    background: RealtyIcon,
    projectClass: 'realty',
    animationDirection: 'slide-right',
    text: [
      {
        key: 1,
        title: 'projects.superRealty.name.title',
        description: 'projects.superRealty.name.description'
      },
      {
        key: 2,
        title: 'projects.superRealty.platform.title',
        description: 'projects.superRealty.platform.description'
      },
      {
        key: 3,
        title: 'projects.superRealty.technology.title',
        description: 'projects.superRealty.technology.description'
      },
      {
        key: 4,
        title: 'projects.superRealty.team.title',
        description: [
          {
            key: 1,
            text: 'projects.superRealty.team.description1',
          },
          {
            key: 2,
            text: 'projects.superRealty.team.description2',
          },
          {
            key: 3,
            text: 'projects.superRealty.team.description3',
          },
          {
            key: 4,
            text: 'projects.superRealty.team.description4',
          },
          {
            key: 5,
            text: 'projects.superRealty.team.description5',
          },
        ]
      },
      {
        key: 5,
        title: 'projects.superRealty.duration.title',
        description: 'projects.superRealty.duration.description'
      },
      {
        key: 6,
        title: 'projects.superRealty.client.title',
        description: 'projects.superRealty.client.description'
      },
    ]
  },
  {
    key: 4,
    logo: RemoteLogo,
    video: RemoteVideo,
    background: RemoteIcon,
    projectClass: 'remote',
    animationDirection: 'slide-left',
    text: [
      {
        key: 1,
        title: 'projects.remoteAssistance.name.title',
        description: 'projects.remoteAssistance.name.description'
      },
      {
        key: 2,
        title: 'projects.remoteAssistance.platform.title',
        description: 'projects.remoteAssistance.platform.description'
      },
      {
        key: 3,
        title: 'projects.remoteAssistance.technology.title',
        description: 'projects.remoteAssistance.technology.description'
      },
      {
        key: 4,
        title: 'projects.remoteAssistance.team.title',
        description: [
          {
            key: 1,
            text: 'projects.remoteAssistance.team.description1',
          },
          {
            key: 2,
            text: 'projects.remoteAssistance.team.description2',
          },
          {
            key: 3,
            text: 'projects.remoteAssistance.team.description3',
          },
          {
            key: 4,
            text: 'projects.remoteAssistance.team.description4',
          },
          {
            key: 5,
            text: 'projects.remoteAssistance.team.description5',
          },
        ]
      },
      {
        key: 5,
        title: 'projects.remoteAssistance.duration.title',
        description: 'projects.remoteAssistance.duration.description'
      },
      {
        key: 6,
        title: 'projects.remoteAssistance.client.title',
        description: 'projects.remoteAssistance.client.description'
      },
    ]
  }
];

export default dataForPortfolio;
