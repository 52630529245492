import React from 'react';

export default function Social() {
  return (
    <svg className='social-img' width="150" height="120" viewBox="0 0 150 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M84.7039 71.6353C82.3005 70.9878 79.4577 71.0835 77.0518 70.4556C75.8216 70.1343 74.4437 70.3599 73.1737 70.4556C71.8443 70.5561 70.5446 70.848 69.2573 71.1742C67.8485 71.5299 66.4397 71.9321 64.9962 72.1136C64.4803 72.1774 63.1529 72.744 63.5572 73.1585C67.0445 76.7492 69.7584 81.0547 71.5492 85.7099C72.0651 87.0515 73.4991 90.4201 73.8563 91.8084C73.9778 92.2793 74.7715 92.4534 74.9898 91.9555C75.915 89.8413 78.7057 86.2936 80.1121 84.5032C81.6102 82.5925 82.8355 80.4979 83.9367 78.342C84.5667 77.1034 84.9968 75.6663 85.5896 74.4105C86.5309 72.4155 85.4738 72.055 85.1663 71.8759C84.8984 71.7214 84.4594 71.795 84.318 72.0966C81.9145 77.1888 79.8281 81.8106 76.1208 86.0999C75.5929 86.7094 75.08 87.3393 74.6411 88.0143C73.3015 83.5609 71.14 79.3476 68.2855 75.6563C67.3827 74.4863 66.4645 73.3522 65.4327 72.2927C65.2864 72.6409 65.14 72.9917 64.9937 73.34C67.5806 73.0187 70.0312 72.0425 72.6181 71.7359C73.8385 71.5912 74.4061 72.95 76.3598 71.5495C77.5379 71.8242 79.3167 70.9461 80.4378 71.395C83.5095 72.6286 85.3563 71.8122 84.7039 71.6353Z" fill="#EB613B" />
      <path d="M105.133 94.7215C102.974 90.0691 99.7235 84.4035 96.4848 80.3933C93.1741 76.2978 88.7969 73.3688 83.6411 72.2024C78.1778 70.9639 72.479 71.4882 67.0157 72.4776C61.5 73.4802 56.1807 75.0987 51.882 78.8534C49.7359 80.7275 47.9366 83.0406 46.7327 85.6289C45.2999 88.7152 45.6587 91.0571 45.0502 94.3859" stroke="#EB613B" strokeWidth="3" />
      <path fillRule="evenodd" clipRule="evenodd" d="M57.9397 46.2343C58.051 42.3158 59.4184 38.5676 61.571 35.3175C63.4946 32.4081 66.1249 29.9246 69.6907 29.3873C72.8837 28.9089 76.1813 29.8525 78.8377 31.6349C85.0469 35.8024 86.7942 43.79 87.0621 50.6836C87.3299 57.5771 86.4732 59.2086 84.301 62.544C82.2988 65.6237 78.9816 67.3209 75.3634 67.5699C68.4607 68.0482 62.8207 63.2451 60.1643 57.1839C58.666 53.7765 57.8351 49.9693 57.9397 46.2343ZM64.0722 36.974C62.1771 39.8354 61.0316 43.0486 60.9385 46.3194C60.8477 49.566 61.5725 52.9333 62.9105 55.9763L62.912 55.9797C65.2379 61.2867 69.9089 64.9407 75.156 64.5771L75.1574 64.577C77.972 64.3833 80.3677 63.0902 81.7857 60.9089L81.7871 60.9067C82.8651 59.2515 83.381 58.3568 83.6989 57.1711C84.0319 55.9294 84.195 54.1622 84.0643 50.8001C83.8025 44.0613 82.0895 37.4308 77.1662 34.1261C75.0209 32.6868 72.4669 32.0052 70.1364 32.354C67.4756 32.7556 65.4901 34.8302 64.0722 36.974Z" fill="#EB613B" />
      <path fillRule="evenodd" clipRule="evenodd" d="M46.4668 70.2702C42.4388 70.5447 39.1477 67.7885 37.5976 64.3104C36.7233 62.3552 36.2384 60.1705 36.2995 58.0273C36.3644 55.7787 37.1623 53.6279 38.4185 51.7629C39.5409 50.0934 41.0758 48.6683 43.1566 48.36C45.0198 48.0855 46.944 48.627 48.4942 49.6497C52.1174 52.0412 53.2924 57.8717 53.2934 60.5804C53.2943 63.2891 52.9498 65.4723 51.6822 67.3862C50.5139 69.1534 48.5781 70.1273 46.4668 70.2702ZM40.0782 52.8788L40.0773 52.8802C38.9958 54.4858 38.351 56.2752 38.2987 58.0842C38.2471 59.8961 38.6586 61.7838 39.4234 63.494L39.4244 63.4963C40.7449 66.4593 43.385 68.4756 46.3308 68.2748C47.9191 68.1673 49.2409 67.4524 50.0138 66.2832L50.0147 66.2818C50.9609 64.8533 51.2943 63.1421 51.2934 60.5811C51.293 59.4551 51.0382 57.5472 50.3682 55.6676C49.6888 53.7612 48.6866 52.1732 47.3927 51.3191C46.1829 50.521 44.7478 50.1475 43.449 50.3385C42.1646 50.5291 41.0535 51.4283 40.0782 52.8788Z" fill="#EB613B" />
      <path className='leftGuy' d="M56.084 66.1359C58.5831 63.2503 58.3269 59.0996 57.2361 55.325C56.7192 53.5347 56.4781 52.0512 55.6241 50.5289C54.5498 48.6139 52.1951 47.488 50.4139 45.7166C48.7043 44.2142 47.3665 47.1853 49.2869 48.2673C50.6955 49.06 52.3301 50.0574 53.3484 51.7922C54.2974 53.4087 54.6616 55.7388 55.1237 57.1981C55.9605 59.8389 55.8377 61.9256 54.0138 64.0306C52.5992 65.6657 55.4329 66.8878 56.084 66.1359Z" fill="#EB613B" />
      <path className='rightGuy' d="M89.8081 66.1359C87.309 63.2503 87.5652 59.0996 88.656 55.325C89.1728 53.5347 89.414 52.0512 90.268 50.5289C91.3423 48.6139 93.697 47.488 95.4782 45.7166C97.1878 44.2142 98.5256 47.1853 96.6052 48.2673C95.1966 49.06 93.562 50.0574 92.5437 51.7922C91.5947 53.4087 91.2305 55.7388 90.7684 57.1981C89.9315 59.8389 90.0544 61.9256 91.8783 64.0306C93.2929 65.6657 90.4592 66.8878 89.8081 66.1359Z" fill="#EB613B" />
      <path fillRule="evenodd" clipRule="evenodd" d="M103.467 71.2702C99.4387 71.5447 96.1476 68.7885 94.5975 65.3104C93.7231 63.3552 93.2383 61.1705 93.2993 59.0273C93.3642 56.7787 94.1622 54.6279 95.4183 52.7629C96.5408 51.0934 98.0757 49.6683 100.156 49.36C102.02 49.0855 103.944 49.627 105.494 50.6497C109.117 53.0412 110.292 59.3717 110.293 61.5804C110.294 63.7891 109.95 66.4723 108.682 68.3862C107.514 70.1534 105.578 71.1273 103.467 71.2702ZM97.0781 53.8788L97.0772 53.8802C95.9957 55.4858 95.3509 57.2752 95.2985 59.0842C95.247 60.8961 95.6585 62.7838 96.4232 64.494L96.4243 64.4963C97.7448 67.4593 100.385 69.4756 103.331 69.2748C104.919 69.1673 106.241 68.4524 107.014 67.2832L107.015 67.2818C107.949 65.8716 108.294 63.681 108.293 61.5812C108.293 60.722 108.044 58.8079 107.362 56.8377C106.666 54.8281 105.655 53.1525 104.393 52.3191C103.183 51.521 101.748 51.1475 100.449 51.3385C99.1645 51.5291 98.0534 52.4283 97.0781 53.8788Z" fill="#EB613B" />
      <path d="M44.778 94.5519C48.4487 97.2628 51.7125 100.142 56.1919 101.263C58.6568 101.758 61.1459 102.14 63.6543 102.399C73.7559 103.438 84.2255 102.571 93.8961 99.5878C98.2205 98.2527 102.409 96.4046 106.051 93.8163C109.562 91.3143 112.433 88.163 114.584 84.5575C118.956 77.2377 120.225 68.4466 118.893 60.2049C118.493 57.7258 117.88 55.2905 117.084 52.9099C112.293 36.79 93.9189 22.0846 86.9954 19.5552C78.5549 16.4719 69.1119 16.254 60.4098 18.4971C52.0273 20.6586 44.2066 25.0768 38.7006 31.4431C35.9887 34.5763 33.9064 38.1772 32.7006 42.0551C31.2382 46.7503 30.9815 51.6999 31.0009 56.5631C31.0396 65.9763 32.3132 75.6665 36.5795 84.2851C38.5166 88.1993 41.1315 91.8592 44.778 94.5519Z" stroke="#EB613B" strokeWidth="3" />
      <path d="M35.7943 82.7904C35.7943 82.7904 39.7943 79.2904 42.2943 76.2904C44.7943 73.2904 47.2943 73.7904 50.2943 73.2904C53.2943 72.7904 65.2943 73.7904 65.2943 73.7904" stroke="#EB613B" strokeWidth="2" />
      <path d="M115.794 82.7888C115.794 82.7888 112.794 79.2888 110.294 76.2888C107.794 73.2888 105.294 73.7888 102.294 73.2888C99.2942 72.7888 92.2942 77.2891 92.2942 77.2891" stroke="#EB613B" strokeWidth="2" />
      <path className='centerGuy' fillRule="evenodd" clipRule="evenodd" d="M71.5165 22.9087C71.415 23.3601 71.3268 24.3588 71.4948 26.3754C71.5636 27.201 70.9501 27.926 70.1246 27.9948C69.299 28.0636 68.574 27.4501 68.5052 26.6246C68.3036 24.2056 68.3947 22.6676 68.7536 21.6852C68.9484 21.1519 69.2662 20.6597 69.7755 20.338C70.2817 20.0183 70.7741 20 70.9999 20C71.8284 20 72.4999 20.6716 72.4999 21.5C72.4999 22.1469 72.0904 22.6982 71.5165 22.9087ZM70.9999 23C71.0006 23 71.0012 23 71.0018 23ZM90.423 27.0257C90.6849 27.8116 90.2602 28.6611 89.4743 28.923C87.6716 29.5239 86.4746 31.2505 85.8707 32.6092C85.5343 33.3662 84.6478 33.7072 83.8908 33.3707C83.1338 33.0343 82.7928 32.1478 83.1293 31.3908C83.8588 29.7495 85.5283 27.0761 88.5256 26.077C89.3115 25.815 90.161 26.2397 90.423 27.0257ZM52.5 27.5C52.5 26.6716 53.1716 26 54 26C55.769 26 57.2097 26.9762 58.2536 27.9826C59.3157 29.0066 60.1679 30.2556 60.7655 31.1947C61.2103 31.8936 61.0042 32.8207 60.3053 33.2655C59.6064 33.7103 58.6793 33.5042 58.2345 32.8053C57.6654 31.9111 56.9677 30.9101 56.1714 30.1424C55.357 29.3572 54.631 29 54 29C53.1716 29 52.5 28.3284 52.5 27.5Z" fill="#EB613B" />
    </svg>

  )
}
