import React, { useState, useEffect } from 'react';

import './CookieBanner.scss';

export default function CookieBanner({ enableForm }) {

  const [bannerClass, setBannerClass] = useState('cookie-banner-container');
  const [renderBanner, setRenderBanner] = useState(true);
  const [showBanner, setShowBanner] = useState(false);

  const onAccept = () => {
    localStorage.setItem('allowCookie', true);
    setBannerClass('cookie-banner-container accepted');
    enableForm(true);
  }

  useEffect(() => {
    if (localStorage.getItem('allowCookie') === 'true') {
      setRenderBanner(false);
    }
    setShowBanner(true)
  }, [])

  if (renderBanner && showBanner) {
    return (<div className={bannerClass}>
      <div className='cookie-banner-wrapper'>
        <p className='cookie-banner-text'>None of the information we gather in this way can be used to identify any individual who visits the site, nor can cookies be used to run programs or deliver viruses to your computer.</p>
        <button onClick={() => onAccept()} className='cookie-banner-btn'>OK</button>
      </div>
    </div>)
  }

  return (
    null
  )
}
