import React from 'react';

export default function Phone() {
  return (
    <svg className='phone-img' width="150" height="120" viewBox="0 0 150 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip001)">
        <path fillRule="evenodd" clipRule="evenodd" d="M49.5003 105.001C59.6746 108.364 77.941 97.9737 81.1254 95.323C98.9118 80.5169 113.558 62.7132 120.5 40.5006C118 33.0006 108.5 24.5008 101.5 15.9044C101.257 15.5351 100.321 15.4301 99.4186 15.5741C98.3015 14.9472 97.1183 14.4598 96.7658 14.6863C91.9064 17.7667 80.492 31.4881 77.2654 35.6582C76.9676 36.0495 78.6394 37.0816 79.8572 37.473C82.7767 41.5 89.1761 47.9012 93.8381 52.2579C88.7195 61.1894 73.384 74.0839 66.7225 79.6017C60.3786 77.433 51.3813 70.9745 49.9543 64.5002C49.8405 64.3278 49.4384 64.2521 48.9478 64.2515C48.4769 63.7977 48.0217 63.5215 47.7658 63.686C43.1957 66.583 34.8046 77.4258 31.1377 82.1754C30.838 82.3021 30.5886 82.5595 30.5003 83.0006C29.6058 87.5259 33.4094 91.8796 36.5004 96.0007C37.3335 97.1115 49.1244 105.492 49.5003 105.001ZM114.5 42.0006C114.789 41.3136 112.816 37.3464 112.56 36.9166C109.647 29.8569 104.523 24.9867 98.9244 18.9596C94.1635 22.407 86.9212 30.37 82.9988 35.0404C86.8347 40.3915 95.3961 48.9751 98.9998 52.0007C99.1564 52.131 99.2021 52.4903 99.1743 52.944C99.4255 53.2285 99.5333 53.4783 99.416 53.6447C94.5387 65.5009 76.1361 78.5711 70.2402 83.4467C70.0212 83.6297 69.5957 83.6072 69.0823 83.4584C68.5873 84.0415 68.1173 84.4284 67.9146 84.28C63.4526 83.1648 52.8752 75.1898 48.398 68.6757C43.9679 72.7597 37.7743 81.2083 34.41 85.7061C38.5705 92.1553 43.3668 96.2918 51.5003 101.001C81.1353 95.4287 101.6 68.6966 114.5 42.0006Z" fill="#EB613B" />
        <path className='smallWave' d="M63.8482 39.0036C61.9337 40.1918 59.1914 41.3638 57.8862 43.2627C56.5128 45.2643 56.9741 47.3597 57.8912 49.2595C59.3608 52.3097 64.1963 49.4126 62.7312 46.3775C62.7384 46.39 62.6372 46.182 62.544 45.966C62.7646 45.8289 62.9852 45.6917 63.2059 45.5545C64.2484 44.9095 65.2884 44.262 66.3309 43.617C69.419 41.7067 66.9339 37.0908 63.8482 39.0036Z" fill="#EB613B" />
        <path className='mediumWave' d="M68.7021 26.2315C62.7637 25.0885 56.8354 28.0037 52.521 32.4899C47.899 37.2934 44.062 43.3731 44.135 49.9398C44.1724 53.4162 49.6081 53.202 49.5714 49.7102C49.5169 44.7641 52.317 40.4083 55.7087 36.6976C58.6689 33.4577 62.7716 30.7144 67.1043 31.5492C70.468 32.1948 72.0886 26.8838 68.7021 26.2315Z" fill="#EB613B" />
        <path className='bigWave' d="M70.3596 13.8114C64.8303 13.5901 59.6815 15.8344 55 18C50.9683 19.865 47.2831 21.3409 43.9999 24.9987C40.624 28.7573 35.9538 34.2519 34.4541 39.0003C32.7945 44.2669 33.8719 50.1961 34.4541 55.4243C34.6152 56.8777 36.3616 57.6095 37.6899 57.2235C39.2562 56.7712 39.9286 55.2596 39.7693 53.8239C39.2914 49.5171 37.1382 44.8311 38.5 40.5003C39.758 36.5088 43.6144 32.1116 46.5 29.0003C52.6709 22.3386 61.3324 18.8931 70.1327 19.2451C73.6072 19.3874 73.8138 13.9494 70.3596 13.8114Z" fill="#EB613B" />
      </g>
      <defs>
        <clipPath id="clip001">
          <rect width="120" height="150" fill="white" transform="translate(150) rotate(90)" />
        </clipPath>
      </defs>
    </svg>
  )
}
