import i18next from "i18next"
// import Backend from "i18next-xhr-backend"
// import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next";


import de from './de.json';
import en from './en.json';

i18next
  .use(initReactI18next)
  .init({
    resources: {
      de,
      en
    },
    lng: 'de',
    fallbackLng: "en",
    ns: ["translation"],
    defaultNS: "translation",
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
    },
  });

i18next.languages = ['de', 'en']

export default i18next;
