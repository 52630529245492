import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import EnChartDesktop from '../../images/Flow/Graphic/EnChartDesktop.svg';
import DeChartDesktop from '../../images/Flow/Graphic/DeChartDesktop.svg';
import EnChartMobile from '../../images/Flow/Graphic/EnChartMobile.svg';
import DeChartMobile from '../../images/Flow/Graphic/DeChartMobile.svg';

import './FlowComponent.scss';

export default function FlowComponent({ lang }) {

  const { t } = useTranslation();

  const [desktopImage, setDesktopImage] = useState();
  const [mobileImage, setMobileImage] = useState();

  useEffect(() => {
    if (lang === 'en') {
      setDesktopImage(EnChartDesktop);
      setMobileImage(EnChartMobile);
    } else {
      setDesktopImage(DeChartDesktop);
      setMobileImage(DeChartMobile);
    }
  }, [lang])

  return (
    <div className='flow-container container-fluid' id='services'>
      <div className='flow-wrapper container'>
        <h3 className='flow-suptitle row justify-content-center'>{t('flow.suptitle')}</h3>
        <h2 className='flow-title row justify-content-center'>{t('flow.title')}</h2>
        <div className='flow-graphic-container row justify-content-center'>
          <img src={desktopImage} alt='Development flow chart' className='flow-graphic col-12 col-lg-10 d-none d-md-block' />
          <img src={mobileImage} alt='Development flow chart' className='flow-graphic col-12 d-block d-md-none' />
        </div>
      </div>
    </div>
  )
}
