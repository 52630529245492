import React from 'react';
import { useTranslation } from 'react-i18next';

import Swipe from 'react-easy-swipe';

import './SliderCard.scss';

export default function SliderCard({ data, slidesAmount, currentSlide, updateKey, dotClick, nextSlide, prevSlide,  animationDirection, setAnimationDirection }) {

  const { img, starImg, title1, title2, totalScore, marks, header, description } = data;

  const { t } = useTranslation();


  const createDotArray = () => {
    const dotArray = new Array(slidesAmount);
    dotArray.fill('slider-card-dot');
    return dotArray;
  }

  const onSwipeRight = () => {
    setAnimationDirection('textShowLeft');
    prevSlide();
  }
  
  const onSwipeLeft = () => {
    setAnimationDirection('textShowRight');
    nextSlide();
  }

  return (
    <Swipe
      onSwipeRight={onSwipeRight}
      onSwipeLeft={onSwipeLeft}
      className='col-xxl-6 col-xl-7 col-lg-9 col-md-12'
      >
      <div className='slider-card-container' >
        <div className='slider-card-wrapper' key={updateKey}>

          <div className='slider-card-title-section' style={{ animationName: `${animationDirection}` }}>
            <div className='slider-card-image-container'>
              <img src={img} alt='Country flag' className='slider-card-image' />
            </div>
            <div className='slider-card-title-container'>
              <span className='slider-card-title up'>{title1}</span>
              <br />
              <span className='slider-card-title bottom'>{title2}</span>
            </div>
          </div>

          <div className='slider-card-rating-container'>

            <div className='slider-card-total-mark-container row no-gutters'>
              <span className='slider-card-total-mark'>{totalScore}</span>
              <div className='slider-card-stars-container ml-2'>
                <img src={starImg} alt='Star rating' className='slider-card-stars-img' />
              </div>
            </div>

            <div className='slider-card-marks'>
              {
                marks.map((mark) => {
                  const { key, param, value } = mark;
                  return (
                    <div className='mark-container' key={key}>
                      <span className='mark-param'>{t(`${param}`)}</span>
                      <span className='mark-value'>{value}</span>
                    </div>
                  )
                })
              }
            </div>
          </div>

          <h5 className='slider-card-header' style={{ animationName: `${animationDirection}` }}>{header}</h5>

          <div className='slider-card-description' style={{ animationName: `${animationDirection}` }}>{description}</div>

          <div className='slider-card-dots-container'>
            {
              createDotArray().map((item, i) => {
                return (
                  <span
                    key={i}
                    className={i === currentSlide ? `${item} active` : item}
                    onClick={() => dotClick(i)}
                    onKeyPress={() => dotClick(i)}
                    role='button'
                    tabIndex='-1'
                    aria-label='slide dot'
                  />
                )
              })
            }
          </div>

        </div>
      </div>
    </Swipe >
  )
}
