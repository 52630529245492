import React from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import NumberFormat from 'react-number-format';
import authHeader from '../../helpers/getEmailHeader';

import './ContactUs.scss';
import Axios from 'axios';

export default function ContactUs({ lang }) {

  const { t } = useTranslation();

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = t('form.errorNameEmpty');
    } else if (values.name.length < 2) {
      errors.name = t('form.errorNameShort');
    } else if (values.name.length > 50) {
      errors.name = t('form.errorNameLong');
    }

    if (!values.phoneNumber) {
      errors.phoneNumber = t('form.errorNumberEmpty');
    } else if (values.phoneNumber.trim().length < 13) {
      errors.phoneNumber = t('form.errorNumberShort');
    }

    if (!values.email) {
      errors.email = t('form.errorEmailEmpty');
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = t('form.errorEmailInvalid');
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      phoneNumber: '',
      email: '',
    },
    validate,
    onSubmit: values => {
      const api_url = process.env.GATSBY_APP_API_URL || 'https://edelivery-dev.os-system.com';
      try {
        const resp = Axios.post(api_url + '/api/form', {
          name: values.name,
          phoneNumber: values.phoneNumber,
          email: values.email,
          site: 'de',
        }, { headers: { ...authHeader() } });
        if (process.env.NODE_ENV !== 'production') {
          console.log(resp);
        }
        navigate(`/${lang}/thank-you`);
      } catch (e) {
        console.log(e)
      }
    }
  });

  return (
    <div className='contactus-container container-fluid' id='contact-us'>
      <div className='contactus-wrapper container'>
        <h3 className='contactus-suptitle row justify-content-center'>{t('form.suptitle')}</h3>
        <h2 className='contactus-title row justify-content-center'>{t('form.title')}</h2>
        <div className='contactus-form-container mx-auto col-12 align-items-center d-flex'>
          <form onSubmit={formik.handleSubmit}>

            <div className='contactus-field-container'>
              <span className='field-icon icon-name' />
              <div className='contactus-input-container'>
                <input
                  type='text'
                  id='name'
                  name='name'
                  placeholder={t('form.placeholderName')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name ? <label htmlFor='name'>{formik.errors.name}</label> : null}
              </div>
            </div>

            <div className='contactus-field-container'>
              <span className='field-icon icon-phone' />
              <div className='contactus-input-container'>
                <NumberFormat
                  format='+49 #############'
                  className='flow-number-input'
                  allowEmptyFormatting
                  id='phoneNumber'
                  name='phoneNumber'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phoneNumber}
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber ? <label htmlFor='name'>{formik.errors.phoneNumber}</label> : null}
              </div>
            </div>

            <div className='contactus-field-container'>
              <span className='field-icon icon-email' />
              <div className='contactus-input-container'>
                <input
                  type='text'
                  id='email'
                  name='email'
                  placeholder={t('form.placeholderEmail')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? <label htmlFor='name'>{formik.errors.email}</label> : null}
              </div>
            </div>

            <button type='submit' className='contactus-button'>{t('form.button')}</button>

          </form>
        </div>
      </div>
    </div>
  )
}
