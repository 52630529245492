import crypto from 'crypto';

const authHeader = () => {
  const d = new Date();
  const hash = crypto.createHash('md5').update(d.toISOString().split('T')[0]).digest('hex');
  return {
    'X-Authorization': hash,
  };
}

export default authHeader;
