import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import SliderCard from './SliderCard';

import dataForReviews from '../../information/dataForReviews';

import './Reviews.scss';


export default function Reviews() {

  const { t } = useTranslation();

  const [currentSlide, setCurrentSlide] = useState(0);

  const maxSlides = dataForReviews.length - 1;
  const slidesAmount = dataForReviews.length;

  const [nextUserImage, setNextUserImage] = useState(dataForReviews[1].userImg);
  const [prevUserImage, setPrevUserImage] = useState(dataForReviews[maxSlides].userImg);
  const [animationDirection, setAnimationDirection] = useState('textShowRight');

  const getNextUserImage = () => {
    if (currentSlide === maxSlides) {
      setNextUserImage(dataForReviews[0].userImg);
    } else {
      setNextUserImage(dataForReviews[currentSlide + 1].userImg);
    }
  }

  const getPrevUserImage = () => {
    if (currentSlide === 0) {
      setPrevUserImage(dataForReviews[maxSlides].userImg);
    } else {
      setPrevUserImage(dataForReviews[currentSlide - 1].userImg);
    }
  }

  useEffect(() => {
    getNextUserImage();
    getPrevUserImage();
  })

  const nextSlide = () => {
    if (currentSlide === maxSlides) {
      setCurrentSlide(0);
    } else {
      setCurrentSlide(currentSlide + 1);
    }
    setAnimationDirection('textShowRight');
  }

  const prevSlide = () => {
    if (currentSlide === 0) {
      setCurrentSlide(maxSlides);
    } else {
      setCurrentSlide(currentSlide - 1);
    }
    setAnimationDirection('textShowLeft');
  }

  return (
    <div className='reviews-container container-fluid' id='reviews'>
      <div className='reviews-wrapper container'>
        <h3 className='reviews-suptitle row justify-content-center'>{t('reviews.suptitle')}</h3>
        <h2 className='reviews-title row justify-content-center'>{t('reviews.title')}</h2>

        <div className='reviews-slider row justify-content-xxl-between justify-content-around'>

          <div className='reviews-slider-prev-user-container col-2 d-none d-xxl-block'>
            <img src={prevUserImage} alt='User flag' className='reviews-slider-prev-user' />
          </div>

          <button className='arrow-prev d-none d-lg-block' onClick={prevSlide} aria-label='Previous slide' />

          <SliderCard
            data={dataForReviews[currentSlide]}
            slidesAmount={slidesAmount}
            currentSlide={currentSlide}
            dotClick={setCurrentSlide}
            updateKey={currentSlide}
            nextSlide={nextSlide}
            prevSlide={prevSlide}
            animationDirection={animationDirection}
            setAnimationDirection={setAnimationDirection}
          />

          <button className='arrow-next d-none d-lg-block' onClick={nextSlide} aria-label='Next slide' />

          <div className='reviews-slider-next-user-container col-2 d-none d-xxl-block'>
            <img src={nextUserImage} alt='User flag' className='reviews-slider-next-user' />
          </div>

        </div>

      </div>
    </div>
  )
}
