import React, { useEffect } from 'react';
import AOS from 'aos';
import { useTranslation } from 'react-i18next';

import dataForPortfolio from '../../information/dataForPortfolio';

import 'aos/dist/aos.css';
import './Portfolio.scss';

export default function Portfolio() {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
      disable: function () {
        let maxWidth = 768;
        return window.innerWidth < maxWidth;
      },
      once: true,
    });
  })

  return (
    <div className='portfolio-container container-fluid' id='projects'>
      <div className='portfolio-wrapper container'>
        <h3 className='portfolio-suptitle row justify-content-center'>{t('projects.suptitle')}</h3>
        <h2 className='portfolio-title row justify-content-center'>{t('projects.title')}</h2>
        <div className='projects-container row justify-content-center no-gutters'>
          {
            dataForPortfolio.map((project) => {
              const { logo, text, video, key, projectClass, background, animationDirection } = project;

              return (
                <div className={`single-project row justify-content-lg-start justify-content-center align-items-lg-start align-items-center ${projectClass}`} key={key}>
                  <img className='project-background-image' src={background} alt='Company icon' data-aos='fade-up' />
                  <div className='project-text-container col-lg-6 col-md-10 col-sm-10 col-xs-10 col-10 order-lg-1 order-2'>
                    <div className='project-logo-container mb-4'>
                      <img src={logo} alt='Company name' className='project-logo' />
                    </div>
                    {
                      text.map((item, i) => {
                        const { title, description, key } = item;
                        if (typeof description === 'object') {
                          return (
                            <div className='project-text-block mb-4' key={key} data-aos={animationDirection}>
                              <h5 className='project-title'>{t(`${title}`)}</h5>
                              <ul className='project-list pl-0'>
                                {
                                  description.map((line) => {
                                    const { key, text } = line;
                                    return (
                                      <li className='project-list-item' key={key}>{t(`${text}`)}</li>
                                    )
                                  })
                                }
                              </ul>
                            </div>
                          )
                        }
                        return (
                          <div className='project-text-block mb-4' key={key} data-aos={animationDirection}>
                            <h5 className='project-title mb-3'>{t(`${title}`)}</h5>
                            <p className='project-paragraph'>{t(`${description}`)}</p>
                          </div>
                        )
                      })
                    }
                  </div>
                  <div className='project-image-container col-lg-6 col-md-6 col-sm-8 col-xs-10 col-10 order-lg-2 order-1'>
                    <div className='project-phone d-flex align-items-start justify-content-center'>
                      <video autoPlay playsInline loop preload="true" muted className='mx-auto'>
                        <source src={video} type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}
